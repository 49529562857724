/* EFIC > Modules > Social | Deepend.com.au (c) 2015 | Author: @elray72 */

var EFIC = EFIC || {};
EFIC.Social = EFIC.Social || {};

// Twitter Feed
EFIC.Social.TwitterFeed = (function ($) {

    var loadTwitterFeed = function () {

        $(document).ready(function () {
            twitterFetcher.fetch(EFIC.Configuration.twitterConfig);
        });
    };

    return {
        init: loadTwitterFeed
    };

}($));
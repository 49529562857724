var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function($, jQuery) {
	'use strict';

	EFIC.Interface.PushBackNav = function( options ) {

		var defaults = {

			selectors: {
				//Module Parent and desktop element selectors
				parentElem: '#master-header',
				navRow: '.nav-row',
				primaryNav: '#primary-nav'
			}
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var parent = $(options.selectors.parentElem);
		var $navRow = $(options.selectors.navRow);
		var $primaryNav = $(options.selectors.primaryNav);

		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
			return $(options.selectors.parentElem+' '+selector);
		}

		/**********************************
		// Interface Events
		**********************************/

		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents(){

			var $header = $('#master-header'),
				previousScrollTop =  $(document).scrollTop();

		    $(document).on('scroll', function (e) {
		    	if ( $primaryNav.is(':hidden') ) return;

		    	var newScrollTop = $(document).scrollTop(),
					topBuffer = $header.outerHeight()/2;

		        if ( newScrollTop > topBuffer) {
		        	$header.addClass('pushback');

		        } else {
		        	$header.removeClass('pushback');
		        }

	        	( newScrollTop < previousScrollTop ) ? $navRow.addClass('push-in') : $navRow.removeClass('push-in');
	        	
		        previousScrollTop = newScrollTop;
		    });
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			// Init
			bindEvents();
		}

		__construct();

		return {
		};
	};


})(jQuery, jQuery);

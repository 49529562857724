var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function($, jQuery) {
	'use strict';

	EFIC.Interface.PopupNotification = function( options ) {

		var defaults = {

			selectors: {
				//Module Parent and desktop element selectors
				parentElem: '#popup-notification',
				btnCta: '.btn-cta'
			},

			// 2 minutes timeout before the notification appears
			timeout: 2 * 60 * 1000, 
			cookieName: 'SBEL'
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var $parent = $(options.selectors.parentElem);
		var $btnCta = $(options.selectors.btnCta);

		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
			return $(options.selectors.parentElem+' '+selector);
		}

		/**********************************
		// Interface Events
		**********************************/

		function checkCookie() {
			// return true if cookie already exist
			return (Cookies.getJSON( options.cookieName ) !== undefined);
		}

		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents() {
			$btnCta.on('click', function(e){
				// set cookie
				Cookies.set( options.cookieName, 1);
			});

			setTimeout( function() {
				$parent.modal('show');
			}, options.timeout );
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			if ( $parent.length === 0 ) return;

			// Check cookie to see if user had dismiss the notification before
			// If true, then don't need to bother them with notification again
			if ( checkCookie() ) return;

			// Init
			bindEvents();
		}

		__construct();

		return;
	};


})(jQuery, jQuery);

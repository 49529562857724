var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function ($, Cookies) {
    'use strict';

    EFIC.Interface.ProductFinder = function (options) {

        var defaults = {

            selectors: {
                parentElem: '.product-finder',
                clientTypeSelect: '#client-type',
                turnoverSelect: '#turn-over',
                loanAmountSelect: '#loan-amount',
                exportExperienceSelect: '#export-experience',
                lookingforSelect: '#looking-for',
                btnRedirect: '.btn-showme'
            },

            cookieName: 'productFinder'
        };

        options = $.extend(true, {}, defaults, options);


        /**********************************
        // Declare variables
        **********************************/
        var parentElem = $(options.selectors.parentElem),
            $clientTypeSelect = $parentElem(options.selectors.clientTypeSelect),
            $turnoverSelect = $parentElem(options.selectors.turnoverSelect),
            $loanAmountSelect = $parentElem(options.selectors.loanAmountSelect),
            $exportExperienceSelect = $parentElem(options.selectors.exportExperienceSelect),
            $lookingforSelect = $parentElem(options.selectors.lookingforSelect),
            $btnRedirect = $parentElem(options.selectors.btnRedirect);

        var turnOverData = window.turnOverData,
            productData = window.productData,            
            filterSequence = [ // Data & Dom mapping, objects are ordered by filter sequence required
                { $el: $clientTypeSelect, name: 'clientType', label: '' },
                { $el: $turnoverSelect, name: 'turningOver', label: 'label' },
                { $el: $loanAmountSelect, name: 'loanAmount', label: 'amount' },
                { $el: $exportExperienceSelect, name: 'exportExperience', label: 'exportExperienceLabel' },
                { $el: $lookingforSelect, name: 'landingPage', label: 'label' }
            ];


        /**********************************
        // Helper function to return a jQuery object, which is a child of 'parentElem'
        **********************************/
        function $parentElem(selector) {
            return $(options.selectors.parentElem + ' ' + selector);
        };


        /**********************************
        // UI and filtering events
        **********************************/
        function setCookie() {
            Cookies.set( options.cookieName, getCurrentFilter() );
        };

        function checkCookie() {
            var cookie = Cookies.getJSON( options.cookieName );

            if (cookie === undefined) return;

            var cKeys = Object.keys(cookie);

            if ( cookie && cKeys.length) {

                // Need prepopulate all fields base on the cookie
                // Setting each select base on the values stored in the cookie
                // Trigger change event for easch select to do all the filtering & population
                _.forEach(filterSequence, function( o, i ){
                    var storedValue = cookie[filterSequence[i].name];

                    filterSequence[i].$el.val( storedValue ).trigger( 'change' );
                });
            }
        };

        function updateFilterValue( $changedSelect ) {
            var newSelectVal = $changedSelect.val(),
                targetId = $changedSelect.attr('id'),
                index = 0;

            var filterObj = _.find(filterSequence, function( o, i ) {
                index = i;
                return ( o.$el.attr('id') === targetId );
            });

            if ( newSelectVal !== 'null' ) {
                filterSequence[index].value = filterObj.value = Number(newSelectVal);
            }

            updateSelectOptions( index + 1 );
        }

        function updateSelectOptions( index ) {

            var $targetSelect = filterSequence[index].$el,
                dataName = filterSequence[index].name,
                dataLabel = filterSequence[index].label;

            // clean subsequent selects first
            resetSubsequentSelects(index);

            // update current filter after cleaning
            var filter = getCurrentFilter();

            var referencingData, filteredData;
            var optionValues = [];

            // Workout the right dataset to filter
            referencingData = ( index === filterSequence.length-1 ) ? productData : turnOverData;

            // Now, filter the dataset with latest filter condition
            filteredData = _.filter( referencingData, filter );

            _.forEach( filteredData, function(d, i) {

                if ( $.inArray(d[dataName], optionValues) === -1 ) {

                    var $option = $('<option>');
                        
                    $option.val( d[dataName] );
                    $option.html( d[dataLabel] );

                    $targetSelect.append( $option );

                    // optionValues keeps tracking which option has already been appended
                    optionValues.push( d[dataName] );
                }
            });

            $targetSelect.prop('disabled', false);
        }

        function getCurrentFilter() {
            // Base on the value stored, generate filter condition obj

            var filter = {};

            _.forEach(filterSequence, function( filterObj, index ){
                if ( filterObj.value ) {
                    filter[filterObj.name] = filterObj.value;
                }
            });

            return filter;
        }

        function resetSubsequentSelects(startIndex) {

            // Clean stored value
            for (var i=startIndex; i < filterSequence.length; i++) {

                delete filterSequence[i].value;
                resetSelect( filterSequence[i].$el );
            }

            updateBtnURL('#');
        }

        function resetSelect($targetSelect) {
            // Clean targeted select in dom
            $targetSelect.empty();
            $targetSelect.append('<option value="null">Please Choose</option>');
            $targetSelect.prop('disabled', true);
        }

        function updateBtnURL(url) {
            $btnRedirect.attr('href', url);

            if ( url === '#' ) 
                $btnRedirect.addClass('disabled');
            else 
                $btnRedirect.removeClass('disabled');
        }

        /**********************************
        // Setup and initializations
        **********************************/
        function bindEvents() {

            $btnRedirect.on('click', function(e) {
                var $btn = $(this);

                ( $btn.hasClass('disabled') ) ? e.preventDefault() : setCookie();
            });

            parentElem.find('select').on('change', function(e) {
                var $select = $(this),
                    lastSelectObj = filterSequence[filterSequence.length-1];

                if ( $select.attr('id') === lastSelectObj.$el.attr('id')) {
                    // if its last select, just store the value & update the link url

                    lastSelectObj.value = $select.val();
                    updateBtnURL( $select.val() );

                } else {

                    updateFilterValue( $select );
                }
            });
        };


        function __construct() {

            // Init
            if (parentElem.length === 0) return;

            bindEvents();
            checkCookie();
        }

        __construct();

        // Make some events accessible from global scope
        return;
    };

})(jQuery, Cookies);
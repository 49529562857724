/* EFIC > Config | Deepend.com.au (c) 2015 | Author: @elray72 */

var EFIC = EFIC || {};

// Site Configuration
EFIC.Configuration = (function($) {
  // EFIC.Configuration.twitterConfig
  var twitterConfig = {
    profile: { screenName: "ExportFinanceAU" },
    domId: "homeTwitterFeed",
    maxTweets: 2,
    enableLinks: true,
    showUser: true,
    showTime: true,
    lang: "en"
  };

  return {
    twitterConfig: twitterConfig
  };
})($);

var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function($, jQuery) {
	'use strict';

	EFIC.Interface.PushDownNotification = function( options ) {

		var defaults = {

			selectors: {
				//Module Parent and desktop element selectors
				parentElem: '.pushdown-notification',
				notification: '.notification-item',
				btnCta: '.btn-cta'
			},

			// 2 minutes timeout before the notification appears
			timeout: 2 * 60 * 1000, 
			cookieName: 'SBEL'
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var $parent = $(options.selectors.parentElem);
		var $btnCta = $(options.selectors.btnCta);
		var $notification = $(options.selectors.notification);

		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
			return $(options.selectors.parentElem+' '+selector);
		}

		/**********************************
		// Interface Events
		**********************************/

		function checkCookie() {
			// return true if cookie already exist
			return (Cookies.getJSON( options.cookieName ) !== undefined);
		}

		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents() {
			$btnCta.on('click', function(e){
				$notification.collapse('hide');

				// set cookie
				Cookies.set( options.cookieName, 1);
			});

			setTimeout( function() {
				$notification.collapse('show');
			}, options.timeout );
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			if ( $parent.length === 0 ) return;

			// Check cookie to see if user had dismiss the notification before
			// If true, then don't need to bother them with notification again
			if ( checkCookie() ) return;

			// If notification is not in the head of the site, move it to header
			// Not all pages have notification
			if ( $parent.parents('#master-header').length === 0 ) {
				$parent.detach();
				$parent.prependTo('#master-header');
			}

			$parent.removeClass('hide');

			// Init
			bindEvents();
		}

		__construct();

		return;
	};


})(jQuery, jQuery);

var EFIC = EFIC || {};
EFIC.Accordion = EFIC.Accordion || {};

(function($, jQuery) {
	'use strict';

	EFIC.Interface.Accordion = function( options ) {

		var defaults = {

			selectors: {
				parentElem: '.accordion',
				itemParentElem: '.accordion-item',
				itemTrigger: '.item-toggle',
				itemContent: '.item-content',
			},
		};

		options = $.extend(true, {}, defaults, options );

		/**********************************
		// Declare variables
		**********************************/
		var parent = $(options.selectors.parentElem);
		var itemTrigger = $(options.selectors.itemTrigger);


		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
		    return $(options.selectors.parentElem + ' ' + selector);
		}

		function $findElem(parent, selector) {
		    return $(parent).find(selector);
		}


		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents(){
			itemTrigger.on('click', function(e) {
				e.preventDefault();

				var $itemParent = $(this).parents(options.selectors.itemParentElem);

				$itemParent.toggleClass('open');
				$itemParent.find(options.selectors.itemContent).collapse('toggle');
			});
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			// Init
			bindEvents();
		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {};
	};


})(jQuery, jQuery);
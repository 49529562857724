var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function($, jQuery) {
	'use strict';

	EFIC.Interface.ResourceFinder = function( options ) {

		var defaults = {

			selectors: {

				//Module Parent and desktop element selectors
				parentElem: '.selected-filters',
				tags: '.tag',
                form: '#filterForm'
			},

			data_attrs: {
			    facet: 'facet',
                value: 'value'
			},

			desktop_options: {
				
			},

			mobile_options: {
				
			}
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var parent = $(options.selectors.parentElem);

		var $tags = $parentElem(options.selectors.tags);

		var $form = $(options.selectors.form);

		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
			return $(options.selectors.parentElem+' '+selector);
		}

		/**********************************
		// Interface Events
		**********************************/
		function findResources(queryString) {
		    var url = window.location.pathname;

		    window.location.href = url + '?' + queryString;
		}

		function getParams() {
		    var params = location.search.split('?')[1].split('&');
		    var queryObj = {};
		    for (var i = 0; i < params.length; i++) {
		        var pair = params[i].split('=');
		        if (queryObj[pair[0]]) {
		            if (!Array.isArray(queryObj[pair[0]])) {
		                queryObj[pair[0]] = [queryObj[pair[0]]];
		            }
		            queryObj[pair[0]].push(pair[1]);
		        } else {
		            queryObj[pair[0]] = pair[1];
		        }
		    }

		    return queryObj;
		}

		function removeFacetValue(params, facet, value) {
		    if (Array.isArray(params[facet])) {
		        var index = params[facet].indexOf(value);
		        if (index > -1) params[facet].splice(index, 1);
		    } else {
		        delete params[facet];
		    }

		    return params;
		}

		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents(){
		    $tags.click(function () {
		        var params = getParams();
		        var facet = $(this).data(options.data_attrs.facet);
		        var value = $(this).data(options.data_attrs.value);

		        var strippedParams = removeFacetValue(params, facet, value);

		        var queryString = $.param(strippedParams, true);

		        findResources(queryString);
		    });
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			// Init
			bindEvents();

		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
			//'sampleEvent': sampleEvent,
		};
	};


})(jQuery, jQuery);

var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function ($) {
    'use strict';

    EFIC.Interface.CasestudyFilter = function (options) {

        var defaults = {

            selectors: {
                parentElem: '.case-study-filter',
                sectorSelect: '#sector-select',
                industrySelect : '#industry-select',
                solutionsSelect: '#solution-select',
                btn: '.btn-filter',
                listingContainer: '.case-study-list',
                listingItem: '.case-study',
                btnLoadMore: '.btn-load-more'
            },

            currentPage: 0,
            cookieName: 'casestudyFilter'
        };

        options = $.extend(true, {}, defaults, options);


        /**********************************
        // Declare variables
        **********************************/
        var parentElem = $(options.selectors.parentElem),
            $sectorSelect = $(options.selectors.sectorSelect),
            $industrySelect = $(options.selectors.industrySelect),
            $solutionsSelect = $(options.selectors.solutionsSelect),
            $btn = $(options.selectors.btn),
            $caseStudyList = $(options.selectors.listingContainer),
            $loadMore = $(options.selectors.btnLoadMore),
            $caseStudyRoot = $(options.selectors.parentElem).data('root');



        /**********************************
        // Helper function to return a jQuery object, which is a child of 'parentElem'
        **********************************/
        function $parentElem(selector) {
            return $(options.selectors.parentElem + ' ' + selector);
        };

        function buildFilter(){
          var industry = $industrySelect.val() != undefined ? $industrySelect.val() : '';
          var sector = $sectorSelect.val() != undefined ? $sectorSelect.val() : '';
          var solution = $solutionsSelect.val() != undefined ? $solutionsSelect.val() : '';
          var taxonomyfilter = industry + ' '+ sector + ' '+ solution;
          return taxonomyfilter.trim();
        };

        function filterListing(){
          options.currentPage = 0;
          $.get( '/umbraco/surface/casestudy/getcasestudies?casestudyroot='+ $caseStudyRoot +'&taxonomyfilter=' + buildFilter() + '&page='+ options.currentPage +'&resultsPerPage=12', function( data ) {
            $caseStudyList.html(data);
            $btn.removeClass('disabled');
            if($(options.selectors.listingContainer + ' .last-item').length > 0){
              $loadMore.hide();
            } else{
              $loadMore.show();
            }
          });
        };

        function loadMore(){
          options.currentPage +=1;
          $.get( '/umbraco/surface/casestudy/getcasestudies?casestudyroot='+ $caseStudyRoot +'&taxonomyfilter=' + buildFilter() + '&page='+ options.currentPage +'&resultsPerPage=12', function( data ) {
            $caseStudyList.append(data);
            $loadMore.removeClass('disabled');
            if($(options.selectors.listingContainer + ' .last-item').length > 0){
              $loadMore.hide();
            } else{
              $loadMore.show();
            }
          });
        };
        /**********************************
        // Setup and initializations
        **********************************/
        function bindEvents() {

            $btn.on('click', function(e) {
                e.preventDefault();
                $(this).addClass('disabled');
                filterListing();
            });

            $loadMore.on('click', function(e) {
                e.preventDefault();
                $(this).addClass('disabled');
                loadMore();
            });

            //hide the loadmore button if the list-item is present on the page
            if($(options.selectors.listingContainer + ' .last-item').length > 0){
              $loadMore.hide();
            } else{
              $loadMore.show();
            }
        };


        function __construct() {

            // Init
            if (parentElem.length === 0) return;

            bindEvents();
        }

        __construct();

        // Make some events accessible from global scope
        return;
    };

})(jQuery);

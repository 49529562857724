var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function($, jQuery) {
	'use strict';

	EFIC.Interface.ScrollTo = function( options ) {

		var defaults = {

			selectors: {
				scrollToTriggerElement: '.scroll-to'
			}
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var $scrollToElements = $(options.selectors.scrollToTriggerElement);



    function bindElements(){
      $scrollToElements.click(function() {
          $('html, body').animate({
              scrollTop: $($(this).attr('href')).offset().top - 150  }, 1000);
          return false;
      });
    }

		function __construct() {

			// Init
			if ($scrollToElements.length > 0){
        bindElements();

			}
		}

    __construct();

    // Make some events accessible from global scope
    return;
};

})(jQuery);

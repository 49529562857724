var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function($, jQuery) {
	'use strict';

	EFIC.Interface.SiteOverlay = function( options ) {

		var defaults = {

			selectors: {
                body: 'body',
                overlay: '.site-overlay',
				toggle: 'button[data-toggle="site-overlay"]'
			},

			classes: {
			    overlayOpen: 'site-overlay-open'
			},

			desktop_options: {
				
			},

			mobile_options: {
				
			}
		};

		options = $.extend(true, {}, defaults, options);

		/**********************************
		// Declare variables
		**********************************/
		var $body = $(options.selectors.body);
		var $overlay = $(options.selectors.overlay);
		var $toggle = $(options.selectors.toggle);

		/**********************************
		// Interface Events
		**********************************/
		function toggleOverlay() {
		    if ($body.hasClass(options.classes.overlayOpen)) {
		        $body.toggleClass(options.classes.overlayOpen);

		        // Don't immediately set display none - first wait until the opacity transition
		        // has finished. The amount of time for the timeout should match the transition
		        // duration in modules/_site-overlay.scss
		        setTimeout(function () {
		            $overlay.css('display', 'none');
		        }, 150);
		    } else {
		        $overlay.css('display', 'block');

		        // Don't immediately toggle the class - first wait a millisecond so that the
		        // overlay has a millisecond to first set display: block. This is because 
		        // CSS does not allow trasitioning from an element with display: none set
		        setTimeout(function () {
		            $body.toggleClass(options.classes.overlayOpen);
		        });
		    }
		}

		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents(){
		    $toggle.click(function (event) {
		        toggleOverlay();
		    });
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			// Init
			bindEvents();

		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
			//'sampleEvent': sampleEvent,
		};
	};


})(jQuery, jQuery);

var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function ($) {
    'use strict';

    EFIC.Interface.ContactForm = function (options) {

        var defaults = {

            selectors: {
                parentElem: '.eficcontactus',
                inputSelect: 'select',
                input_enquiryReason: '#e39f443a-ffb1-4c6b-ad68-7a109faf1a67',
                input_currentTurnover: '#a804d853-8caf-442d-dfe9-97898012ff51',
                input_timeExporting: '#ea206dc8-96f4-4575-a9d2-ad910fb6a8a7',
                btnSubmit: 'input[type=submit]'
            },
        };

        options = $.extend(true, {}, defaults, options);


        /**********************************
		// Declare variables
		**********************************/
        var parentElem = $(options.selectors.parentElem);
        var $inputSelect = $parentElem(options.selectors.inputSelect);
        var $input_enquiryReason = $parentElem(options.selectors.input_enquiryReason);
        var $input_currentTurnover = $parentElem(options.selectors.input_currentTurnover);
        var $input_timeExporting = $parentElem(options.selectors.input_timeExporting);
        var $btnSubmit = $parentElem(options.selectors.btnSubmit);

        /**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
        function $parentElem(selector) {
            return $(options.selectors.parentElem + ' ' + selector);
        };


        /**********************************
		// UI events
		**********************************/
        function hideSubmit(){
            $btnSubmit.hide();
        }

        function showSubmit(){
            $btnSubmit.show();
        }

        /**********************************
		// Setup and initializations
		**********************************/
        function bindEvents() {

            $inputSelect.change(function () {

                console.log('change');

                var enquiryReason = $input_enquiryReason.val();
                var currentTurnover = $input_currentTurnover.val();
                var timeExporting = $input_timeExporting.val();

                if ( enquiryReason == 'Finance Solution'){

                    if ( currentTurnover == 'Less than $2M' || currentTurnover == '$2M - $5M' || currentTurnover == '$5M - $10M' ){

                        if ( timeExporting == '2 - 5 years' || timeExporting == 'More than 5 years'){
                            hideSubmit();

                        } else {
                            showSubmit();
                        }

                    } else {
                        showSubmit();
                    }

                } else {
                    showSubmit();
                }
            });

        };


        function __construct() {
            // Init
            if (parentElem.length > 0) {

                bindEvents();
            }
        }

        __construct();

        //
        // Make some events accessible from global scope
        //
        return {
        };
    };


})(jQuery);

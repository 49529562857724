/* EFIC > Main | Deepend.com.au (c) 2015 | Author: Ray Ranola */

$(document).ready(function () {

    // Load twitter feed
    EFIC.Social.TwitterFeed.init();

    // Modify exporter map
    EFIC.Map.init();

    /*
    REMOVING AS PART OF HOMEPAGE REBUILD
    BW 24/12/2016

    RESTORING
    BW BW 09/03/2016
    */
    EFIC.Nav.MobileBreadcrumb.init();


    var exporterJourneyModule = $('.exporter-journey');
    if (exporterJourneyModule.length) {
        EFIC.ExpJourney.init();
    }

    /*
    REMOVING AS PART OF HOMEPAGE REBUILD
    BW 24/12/2016
    // Init button panel Toggles
    $('.btn-toggle-panel').PanelToggle();
    */

    EFIC.Map.init();
    // EFIC.Interface.PromoTiles();

    //Form tooltips
    $('.tooltip').tooltipster({
        trigger: 'click',
        contentAsHTML: true,
        interactive: true
    });

    EFIC.Interface.CheckableDropdown();
    EFIC.Interface.PushBackNav();
    EFIC.Interface.SiteOverlay();
    EFIC.Interface.LoanCalculator();
    EFIC.Interface.HomeSlider();
    EFIC.Interface.ProductFinder();
    EFIC.Interface.CasestudyFilter();
    EFIC.Interface.ScrollTo();

    EFIC.Interface.PrimaryNavigation();
    EFIC.Interface.PrimaryNavigation({ parentElem: ".utility-links" });
    EFIC.Interface.ResourceFinder();
    EFIC.Interface.UtilityBar();
    EFIC.Interface.Accordion();
    EFIC.Interface.ContactForm();
    EFIC.Interface.PushDownNotification();
    EFIC.Interface.PopupNotification();

    //Init Picture fill
    picturefill();

    //$('.loan-calculato').accrue();

    //Forcing some inputs to be numbers only
    $('.numbers-only').keypress(function (evt) {

        var charCode = (evt.which) ? evt.which : event.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        } else {
            return true;
        }
    });

    // Bind the loan calculator ga event tracking events.
    $(".accrue-calculate").on("click", function () {

        if (!window.ga) {
            return;
        }

        // Ensure string coercion.
        var label = "" + $(".loan-amount").val() + "|" + $(".loan-term").val();

        //                         Category                                     Action                          Label
        window.ga("send", "event", "Interest Rate Loan Calculator Interaction", "Calculate Repayments Clicked", label);

    });

    // Prevent double submission of the Umbraco Forms.
    // Script provided by Umbraco.
    // http://jira.deepend.com.au/browse/EFIC-202
    var formValidator = $("form").data("validator");
    if (formValidator) {
        formValidator.settings.submitHandler = function (form) {

            if (console && console.log) {
                console.log("Form Passed Validation, disable Submit button");
            }

            //Go find the submit button
            var submit = $("form input[type=submit]");
            //Set the attribute to disabled to prevent further submissions
            submit.attr("disabled", "disabled");
            //Submit the form now it's passed validation
            form.submit();
        };
    }


    // $(window).on( 'beforeunload', function() {
    //     return 'Didn’t find what you need? \n Contact us to find out how we can help you.';
    // });
});

/* EFIC > Modules > Social | Deepend.com.au (c) 2015 | Author: @elray72 */

var EFIC = EFIC || {};

// Twitter Feed
EFIC.ExpJourney = (function ($) {

    var init = function () {


        $('.export-block').first().waypoint( function(direction) {
            $('.nav-square div').hide();
            $('.nav-square').removeClass('active-export');

            if (direction === 'up') {
                $('.nav-square').first().addClass('active-export');
                $('.nav-square div').first().toggle( "slide", {direction: 'right'}, 200 );
            }

        });

        $('.export-block').eq(1).waypoint( function() {
            $('.nav-square div').hide();
            $('.nav-square').removeClass('active-export');
            $('.nav-square').eq(1).addClass('active-export');
            $('.nav-square div').eq(1).toggle( "slide", {direction: 'right'}, 200 );
        }, {
            offset: '25%'
        });

        $('.export-block').eq(2).waypoint( function() {
            $('.nav-square div').hide();
            $('.nav-square').removeClass('active-export');
            $('.nav-square').eq(2).addClass('active-export');
            $('.nav-square div').eq(2).toggle( "slide", {direction: 'right'}, 200 );
        }, {
            offset: '25%'
        });

        $('.export-block').eq(3).waypoint( function() {
            $('.nav-square div').hide();
            $('.nav-square').removeClass('active-export');
            $('.nav-square').eq(3).addClass('active-export');
            $('.nav-square div').eq(3).toggle( "slide", {direction: 'right'}, 200 );
        }, {
            offset: '25%'
        });
        $('.export-block').eq(4).waypoint( function() {
            $('.nav-square div').hide();
            $('.nav-square').removeClass('active-export');
            $('.nav-square').eq(4).addClass('active-export');
            $('.nav-square div').eq(4).toggle( "slide", {direction: 'right'}, 200 );
        }, {
            offset: '25%'
        });
        $('.export-block').eq(5).waypoint( function() {
            $('.nav-square div').hide();
            $('.nav-square').removeClass('active-export');
            $('.nav-square').eq(5).addClass('active-export');
            $('.nav-square div').eq(5).toggle( "slide", {direction: 'right'}, 200 );
        }, {
            offset: '25%'
        });
        $('.export-block').eq(6).waypoint( function() {
            $('.nav-square div').hide();
            $('.nav-square').removeClass('active-export');
            $('.nav-square').eq(6).addClass('active-export');
            $('.nav-square div').eq(6).toggle( "slide", {direction: 'right'}, 200 );
        }, {
            offset: '25%'
        });
        $('.export-block').eq(7).waypoint( function() {
            $('.nav-square div').hide();
            $('.nav-square').removeClass('active-export');
            $('.nav-square').eq(7).addClass('active-export');
            $('.nav-square div').eq(7).toggle( "slide", {direction: 'right'}, 200 );
        }, {
            offset: '25%'
        });
        $('.export-block').eq(8).waypoint( function() {
            $('.nav-square div').hide();
            $('.nav-square').removeClass('active-export');
            $('.nav-square').eq(8).addClass('active-export');
            $('.nav-square div').eq(8).toggle( "slide", {direction: 'right'}, 200 );
        }, {
            offset: '25%'
        });

        $('.nav-square').click(function() {
            $('.nav-square').removeClass('active-export');
            $(this).addClass('active-export');
        });

        // Smooth Scroll
        $('a[href*=#]:not([href=#])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000);

                    return false;
                }
            }
        });


        $('.nav-square div').hide();
        $('.nav-square').on('mouseenter', function() {
            $(this).find($('.nav-square div')).delay(200).toggle( "slide", {direction: 'right'}, 200 );
        });

        $('.nav-square').on('mouseleave', function() {
            $(this).find($('.nav-square div')).fadeOut('fast');
        });

        $('.arrow-circle').on('click', function(e) {
          e.preventDefault();
          var offset = $(this).closest($('.export-block')).next().offset().top;
          $('html, body').stop().animate({ scrollTop: offset }, 400);
        });

    }


    return {
        init: init
    };

}($));


/* EFIC > Modules > Social | Deepend.com.au (c) 2015 | Author: @elray72 */

/*
REMOVING AS PART OF HOMEPAGE REBUILD
BW 24/12/2016

-Reference removed from grunt file

RESTORING - Breadcrumb functionality needed
BW 09/03/2016

-Added reference back into grunt file
*/


var EFIC = EFIC || {};
EFIC.Nav = EFIC.Nav || {};

// Mobile Breadcrumbs
EFIC.Nav.MobileBreadcrumb = (function ($) {

    var init = function () {

        clickEvents();
    },

    clickEvents = function() {

        $('.mobile-breadcrumb .btn-expand').click(function() {

            var elem = $(this);
            var container = elem.closest('.mobile-breadcrumb');

            if (container.hasClass('open')) {
                container.removeClass('open');
                elem.html('+');
            }
            else {
                container.addClass('open');
                elem.html('-');
            }

        });

    };

    return {
        init: init
    };

}($));

// Panel Toggle
$.fn.PanelToggle = function () {

    var mouseEvents = function () {

        $('.btn-toggle-panel').click(function () {

            var elem = $(this);
            var targetElemName = elem.data('target');
            var targetElems = $('.' + targetElemName);

            $.each(targetElems, function(key, value) {

                var item = $(value);
                if (item.hasClass('collapsed')) {
                    item.removeClass('collapsed').addClass('expand');
                }
                else {
                    //item.removeClass('expand').addClass('collapsed');
                }
            });

        });

    },
    init = function () {

        mouseEvents();
    };

    init();
};
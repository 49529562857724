var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function ($) {
    'use strict';

    EFIC.Interface.PrimaryNavigation = function (options) {
        var o;
        var defaults = {

            selectors: {
                //Top level site wrapper - needed for mobile nav
                masterContainer: '#master-container',

                //Module Parent and desktop element selectors
                parentElem: '#primary-nav',
                primaryNav: '.primary-list',
                primaryNavItem: '> li',
                subNav: '.sub-nav',

                //Mobile selectors
                mobileNav: '#mobile-nav',
                closeBtn: '.close-nav-btn'
            }
        };

        o = options = $.extend(true, {}, defaults, options);


        /**********************************
		// Declare variables
		**********************************/

        // TODO: I am not used
        var $masterContainer = $(options.selectors.masterContainer);
        var $parent = $(options.selectors.parentElem);
        var $primaryNav = $parentElem(options.selectors.primaryNav);
        var $primaryNavItem = $primaryNav.find(options.selectors.primaryNavItem);

        // TODO: I am not used
        var $subNav = $parentElem(options.selectors.subNav);
        var $mobileNav = $(options.selectors.mobileNav);

        // TODO: I am not used
        var $closeBtn = $(options.selectors.closeBtn);

        /**********************************
		// Helper function to return a jQuery object, which is a descendent of 'parentElem'
		**********************************/
        function $parentElem(selector) {
            return $(selector, options.selectors.parentElem);
        }

        /**********************************
		// Interface Events
		**********************************/
        function showSubNav(primaryNavTrigger, targetSubnavElem) {

            // Let's just grab this from the DOM every time yeah! Who cares about the impact.
            $(targetSubnavElem).css('display', 'block');
            $(targetSubnavElem).velocity({

                properties: {
                    opacity: 1,
                    top: '-=10px'
                },

                options: {
                    duration: 150,
                    mobileHA: false
                }
            });
        }

        function hideSubNav(primaryNavTrigger, targetSubnavElem) {
            $(targetSubnavElem).velocity({

                properties: {
                    opacity: 0,
                    top: '+=10px'
                },

                options: {
                    duration: 150,
                    mobileHA: false,

                    complete: function () {
                        $(targetSubnavElem).css('display', 'none');
                    }
                }
            });
        }

        /**********************************
		// Setup Events
		**********************************/
        //General events
        function bindEvents() {

        }

        //Primary Nav specific events
        function bindPrimaryNavEvents($targetElem) {
            var $primaryNavTrigger = $targetElem;

            // Look what you made me do!
            var primaryNavElem = $primaryNavTrigger.find('a:first-child');

            //if(!primaryNavElem.length) {primaryNavElem = $targetElem;}

            var targetSubNav = $primaryNavTrigger.find(options.selectors.subNav);

            // if (!targetSubNav.length) { targetSubNav = $primaryNavTrigger.next(options.selectors.subNav); }
            $primaryNavTrigger.hoverIntent({
                over: function () {
                    primaryNavElem.addClass('active');
                    showSubNav($primaryNavTrigger, targetSubNav);
                },

                out: function () {
                    primaryNavElem.removeClass('active');
                    hideSubNav($primaryNavTrigger, targetSubNav);
                },

                timeout: 250
            });
        }

        /**********************************
		// Mobile nav functions
		**********************************/
        function initMobileNav() {

            //Clone the primary nav into the mobile nav
            var navClone = $parent.html();

            if (!navClone) {
                return false;
            }

            $mobileNav.append(navClone);

            // Init the mobile nav plugin
            $mobileNav.mmenu({
                slidingSubmenus: false,

                offCanvas: {
                    position: 'left',
                    zposition: 'next',
                    blockUI: true

                }
            });

            return true;
        }

        /**********************************
		// Simple constructor
		**********************************/
        // TODO: This isn't how you bind a constructor. Use a function and then add other methods to the function prototype.
        function __construct() {
            // Init

            initMobileNav();

            // Bind primary nav rollover events.
            // TODO: This is horribly inefficient. Events should bubble up, not be bound to each item.
            $primaryNavItem.each(function () {
                bindPrimaryNavEvents($(this));
            });

            bindPrimaryNavEvents($(".utility-nav"));

        }

        __construct();

        //
        // Make some events accessible from global scope
        //
        return {
            //'sampleEvent': sampleEvent,
        };
    };

}(jQuery));


(function ($, $s) {


    function Nav() {

    }

    Nav.prototype.mobileNac = function () {

    };


}(jQuery, window.SITE || (window.SITE = {})));
/* EFIC > Modules > Social | Deepend.com.au (c) 2015 | Author: @elray72 */

var EFIC = EFIC || {};

// Twitter Feed
EFIC.Map = (function ($) {

    var init = function () {

        $('.back-to-country-view').click(function () {

            $('.hideOnCountryClick').show();
            $('.showOnCountryClick').hide();

        });

    },

    loadCountryContent = function (country) {

        country = country.replace(' ', '-')
                         .replace(' ', '-')
                         .replace(' ', '-');

        $('#countryList').hide();
        $('.hideOnCountryClick').hide();
        $('.showOnCountryClick').show();
        $('#regionIframe').attr('src', '/education-and-tools/our-export-environment/' + country + '?alttemplate=CountryContent');
    };


    return {
        load: loadCountryContent,
        init: init
    };

}($));

$('#regionIframe').load(function () {

    setTimeout(function () {

        $('#regionIframe').height(10);
        var iFrameHeight = $('#regionIframe').contents().height();
        $('#regionIframe').height(iFrameHeight);

    }, 50);

});
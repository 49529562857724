var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function ($, jQuery) {
    'use strict';

    EFIC.Interface.LoanCalculator = function (options) {

        var defaults = {

            selectors: {
                parentElem : '.loan-calculator',
                input_loanTerm : '.loan-term',
                input_accrueRate : '.accrue-rate',
                input_loanAmount: '.loan-amount',
                elem_interestRate : '.interest-rate .value',
                applyLink : '.apply-link',
                btn : '.btn-calculate'
            }
        };

        options = $.extend(true, {}, defaults, options);


        /**********************************
		// Declare variables
		**********************************/
        var parentElem = $(options.selectors.parentElem);
        var input_loanTerm = $parentElem(options.selectors.input_loanTerm);
        var input_accrueRate = $parentElem(options.selectors.input_accrueRate);
        var input_loanAmount = $parentElem(options.selectors.input_loanAmount);
        var elem_interestRate = $parentElem(options.selectors.elem_interestRate);
        var $applyLink = $parentElem(options.selectors.applyLink);
        var $btn = $parentElem(options.selectors.btn);

        /**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'.
        // JMJS: JQuery has a second parameter in it's selector where you can pass the context.
        // Do people read documentation anymore?
		**********************************/
        function $parentElem(selector) {
            return $(options.selectors.parentElem + ' ' + selector);
        };

        function updateInterestRate() {

            //Get selected option data attribute, plug into top level loan object
            var interestRate = $(input_loanTerm).find('option:selected').data('rate');

            //Update the hidden rate field
            input_accrueRate.val(interestRate + '%');
            //Update the DOM
            elem_interestRate.html(Number(interestRate).toFixed(2));
        }

        function formatNumber (num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        }

        function bindEvents() {

            input_loanTerm.change(function () {
                updateInterestRate();
                $btn.trigger('click');
            });
            
            input_loanAmount.focus(function () {
                var inputVal = $(this).val();

                if (inputVal == '0'){
                    $(this).val('');
                }
            });

            input_loanAmount.blur(function () {
                var inputVal = $(this).val();

                if (inputVal == ''){
                    $(this).val('0');
                }
            });

            // I've bound this separately since the plugin is too inflexible to 
            // allow me to bind it when I need it and not on init.
            $btn.on("click", function () {
                $applyLink.removeAttr("hidden");
            });
        };


        function __construct() {

            // Init
            if (parentElem.length > 0) {

                bindEvents();
                updateInterestRate();

                //Init the accrue plugin
                // JMJS: I am genuinely amazed that we are using a plugin for this. 
                // It's about 5 minutes worth of maths to calculate the changes we required. 
                // Whoever implemented it should be ashamed; this whole setup is horribly overengineered.
                parentElem.accrue({
                    operation: 'button',
                    button_label: "Calculate monthly repayments",
                    response_output_div: ".results",
                    callback: function( elem, data ) {
                        if (data === 0) return;
                        
                        var monthlyInterest = (data.total_interest_formatted/data.num_payments).toFixed(2),
                            monthlyPrincipal = (data.payment_amount - monthlyInterest).toFixed(2);

                        elem.find('.monthly-interest span').text(formatNumber(monthlyInterest));
                        elem.find('.monthly-principal span').text(formatNumber(monthlyPrincipal));
                    },
                    error_text:
                        '<div class="monthly-payment">' + 
                            '<div class="label">Monthly Payment:</div>' +
                            '<div class="value">$<span>0</span></div>' +
                        '</div>' +

                        '<div class="number-payments">' + 
                            '<div class="label">Number of Payments:</div>' +
                            '<div class="value"><span>0</span></div>' +
                        '</div>' +
                        
                        '<div class="monthly-principal">' + 
                            '<div class="label">Monthly Principal:</div>' +
                            '<div class="value">$<span>0</span></div>' +
                        '</div>' +
                        
                        '<div class="monthly-interest">' + 
                            '<div class="label">Monthly Interest:</div>' +
                            '<div class="value">$<span>0</span></div>' +
                        '</div>' +
                        
                        '<div class="total-payments">' + 
                            '<div class="label">Total Payments:</div>' +
                            '<div class="value">$<span>0</span></div>' +
                        '</div>',

                    response_basic:
                        '<div class="monthly-payment">' + 
                            '<div class="label">Monthly Payment:</div>' +
                            '<div class="value">$<span>%payment_amount%</span></div>' +
                        '</div>' +

                        '<div class="number-payments">' + 
                            '<div class="label">Number of Payments:</div>' +
                            '<div class="value"><span>%num_payments%</span></div>' +
                        '</div>' +
                        
                        '<div class="monthly-principal">' + 
                            '<div class="label">Monthly Principal:</div>' +
                            '<div class="value">$<span>0</span></div>' +
                        '</div>' +
                        
                        '<div class="monthly-interest">' + 
                            '<div class="label">Monthly Interest:</div>' +
                            '<div class="value">$<span>0</span></div>' +
                        '</div>' +
                        
                        '<div class="total-payments">' + 
                            '<div class="label">Total Payments:</div>' +
                            '<div class="value">$<span>%total_payments%</span></div>' +
                        '</div>',
                });
            }
        }

        __construct();

        //
        // Make some events accessible from global scope
        //
        return {
        };
    };


})(jQuery, jQuery);
var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function($, jQuery) {
	'use strict';

	EFIC.Interface.CheckableDropdown = function( options ) {

		var defaults = {

			selectors: {

				//Module Parent and desktop element selectors
			    parentElem: '.dropdown-checkable',
			    dropdownTrigger: '.dropdown-toggle',
			    dropdownMenu: '.dropdown-menu',
			    checkbox: 'input[type="checkbox"]',
			    checkedCheckbox: 'input[type="checkbox"]:checked',
                resetBtn: 'button[type="reset"]'
			},

			desktop_options: {
				
			},

			mobile_options: {
				
			}
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var parent = $(options.selectors.parentElem);

		var $dropdownMenus = $parentElem(options.selectors.dropdownMenu);
		var $resetBtn = $(options.selectors.resetBtn);

		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
		    return $(options.selectors.parentElem + ' ' + selector);
		}

		function $findElem(parent, selector) {
		    return $(parent).find(selector);
		}

		/**********************************
		// Interface Events
		**********************************/

		function updateContentTypeCount(dropdown, trigger) {
		    var checkedItems = $findElem(dropdown, options.selectors.checkedCheckbox);
		    if (checkedItems.length > 1) {
		        $(trigger).text(checkedItems.length + ' Selected');
		    } else if (checkedItems.length === 1) {
		        var label = checkedItems.next('.label').text();
		        $(trigger).text(label);
		    } else {
		        $(trigger).text('Select');
		    }
		}

		function triggerCheckboxChange() {
		    setTimeout(function () {
		        var $checkboxes = $parentElem(options.selectors.checkbox);
		        $checkboxes.prop('checked', false);
		        $checkboxes.trigger('change');
		    });
		}

		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents() {
		    $(parent).each(function () {
		        var self = this,
                    trigger = $findElem(self, options.selectors.dropdownTrigger),
                    checkboxes = $findElem(self, options.selectors.checkbox);

		        $(checkboxes).change(function () {
		            updateContentTypeCount(self, trigger);
		        });

		        updateContentTypeCount(self, trigger);
		    });

		    $dropdownMenus.click(function (event) {
		        event.stopPropagation();
		    });

		    $resetBtn.click(function () {
		        triggerCheckboxChange();
		    })
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			// Init
			bindEvents();
			
		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
			//'sampleEvent': sampleEvent,
		};
	};


})(jQuery, jQuery);

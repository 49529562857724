var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function($, jQuery) {
	'use strict';

	EFIC.Interface.HomeSlider = function( options ) {

		var defaults = {

			selectors: {
				parentElem: '.home-slider',
				btnPrev: '.swiper-btn.prev',
				btnNext: '.swiper-btn.next',

			},
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var parentElem = $(options.selectors.parentElem);
		var $btnPrev = $parentElem(options.selectors.btnPrev);
		var $btnNext = $parentElem(options.selectors.btnNext);


		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
			return $(options.selectors.parentElem+' '+selector);
		};

		function __construct() {

			// Init
			if (parentElem.length > 0){

				var homeSlider = new Swiper ('.home-slider', {

					direction: 'vertical',
					loop: true,
					autoplay: 5000,
					autoHeight: true,
					pagination: '.swiper-pagination',
					paginationClickable: true,
					paginationHide: false,
					preventClicks: false,
					preventClicksPropagation: false,
			        nextButton: '.swiper-btn.next',
        			prevButton: '.swiper-btn.prev'

				})

				//Custom next / prev event binding required.
				$btnPrev.click(function(){
					homeSlider.swipePrev();
				});

				$btnNext.click(function(){
					homeSlider.swipeNext();
				});
			}
		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
		};
	};


})(jQuery, jQuery);

/*
REMOVING AS PART OF HOMEPAGE REBUILD
BW 24/12/2016

function toggleSubMobile(elem){
  if ($(elem).find('.sidr-class-cbp-tm-submenu-holder').first().hasClass('sidr-class-hide')) {
    $(elem).find('.sidr-class-cbp-tm-submenu-holder').first().removeClass('sidr-class-hide');
  } else {
    $(elem).find('.sidr-class-cbp-tm-submenu-holder').first().addClass('sidr-class-hide');
  }
}
*/

$(document).ready(function () {

    // Add the ability to zoom into images.
    $(".zoomable img").each(function () {

        var $this = $(this),
            src = $this.prop("srcset"),
            srcArray = src.split("?");

        if (srcArray.length > 1) {
            src = srcArray[0] + "?width=1200";
        }

        $this.addClass("pointer")
             .magnificPopup({
                 items: {
                     src: src
                 },
                 type: "image"
             });
    });

    // home page swiper
    /*
    var homePageSwiper = $('#home-page .swiper-container').swiper({
        mode: 'horizontal',
        loop: true,
        simulateTouch: false,
        followFinger: false,
        autoplay: 3000,
        onSlideChangeStart: function(swiper) {
            var slideid = $('.swiper-slide-active').attr('data-id');
            $('#home-page .slide-content').hide();
            $('#' + slideid).show();
        }
    });


    // other swipers
    var mySwiper = $('.swiper-container').not('#home-page .swiper-container').swiper({
		mode:'horizontal',
		loop: true,
		simulateTouch: false,
		followFinger: false,
		//autoplay: 3500,
		//autoplayDisableOnInteraction: false
	});


    $('.left-arrow').click(function () {
        if (homePageSwiper) {
            homePageSwiper.swipePrev();
            homePageSwiper.startAutoplay();
        }
        if (mySwiper) {
            mySwiper.swipePrev();
        }
    });

    $('.right-arrow').click(function () {
        if (homePageSwiper) {
            homePageSwiper.swipeNext();
            homePageSwiper.startAutoplay();
        }
        if (mySwiper) {
            mySwiper.swipeNext();
        }
    });
	*/

    /*
	REMOVING AS PART OF HOMEPAGE REBUILD
	BW 26/12/2016

	RESTORING - NEEDED ON DEEPER PAGES
	BW 10/3/2016
	*/
    var isMobile = false;
    if (WURFL.is_mobile) {
        isMobile = true;
    }

    if (isMobile == false) {
        $('.tall-tile').each(function (index, value) {
            $(this).find('.hidden-tile-bg-over').addClass("hidden-tile-background-hover").removeClass("hidden-tile-background");
            $(this).find('.hidden-tile-bg-over').hide();
            $(this).find('.hidden-tile-content').hide();
            $(this).find('.tile-button').css("background-color", "#52ae32");
        });

        // Desktop version

        $('.tall-tile').on('mouseenter', function () {
            $(this).find('.tile-button.tall-tile-button').css("background-color", "#86be1c");
            $(this).find('.tall-tile-image .country-tall-image').show();
            $(this).find('.hidden-tile-bg-over').show();
            $(this).find('.hidden-tile-content').show();
            $(this).find('.tile-button').css("background-color", "#5d6569");
            //$(this).find('.hidden-tile-bg-over').addClass("hidden-tile-background-hover").removeClass("hidden-tile-background");
        });

        $('.tall-tile').on('mouseleave', function () {
            $(this).find('.tile-button.tall-tile-button').css("background-color", "#5d6569");
            $(this).find('.hidden-tile-bg-over').hide();
            $(this).find('.hidden-tile-content').hide();
            $(this).find('.tile-button').css("background-color", "#52ae32");
            //$(this).find('.hidden-tile-bg-over').addClass("hidden-tile-background").removeClass("hidden-tile-background-hover");
        });

        $('.relate-tile').on('mouseenter', function () {
            $(this).find('.tile-content').show();
        });

        $('.relate-tile').on('mouseleave', function () {
            $(this).find('.tile-content').hide();
        });
    }

    $('.tall-tile').on('click', function (event) {
        //console.log('tall tile image clicked');
        //$(this).find('.tall-tile-image').toggle();
        $(this).find('.tall-tile-button').addClass("greyBG");

        if ($(window).width() < 480) {

            var attr = $(this).find('a').attr('data-url');
            if (typeof attr !== typeof undefined && attr !== false) {
                window.location = attr;
            } else {
                attr = $(this).find('a').attr('href');
                window.location = attr;
            }
        }
    });

    if ($(window).width() > 480) {
        $('.tall-tile, .relate-tile').click(function () {
            var link = $(this).find('a').first().attr('href');

            if ((link == '#') || (link.indexOf('javascript') > -1)) {

            } else {
                window.location.href = link;
            }
        });
    }


    /*
	$('#home-page .slide-content').each(function(index,value){
		if (index == 0) {
			$(this).show();
		} else {
			$(this).hide();
		}
	});
	*/

    //$('.article img').each(function (index, obj) {
    //    var $this = $(this);
    //    $this.attr('title', $this.attr('alt'));
    //}).tooltip({
    //    track: true
    //});

    // JMJS: Commenting out for now as the client doesn't approve.
    //$(".article img").on("mouseenter",function() {
    //    var $this = $(this),
    //        alt = $this.attr("alt");

    //    if (alt.length) {
    //        var $tooltip = $("<span/>").addClass("alt").html($this.attr("alt"));
    //        $this.after($tooltip);
    //    }

    //}).on("mouseleave",function() {
    //    $(this).next(".alt").remove();
    //});


    /*
	REMOVING AS PART OF HOMEPAGE REBUILD
	BW 24/12/2016

	$('#home-page .mini-slider-border a').click(function() {
		var slideid = $('.swiper-slide-active').attr('data-id');
		$('#home-page .slide-content').hide();
		$('#' + slideid).show();
	});
    

	$('.partner-read-more').on('click', function(event) {
		// event.stopPropagation();
		event.preventDefault();

		$(this).text('');
		//console.log(' read more clicked');
		$(this).closest('.partner-tile-tall').addClass("expand-tile");
		$(this).closest('.partner-text').addClass("expand-text");
	});
	*/

    // Populate the correct fields in the newsletter modal popup.
    $('.search-modal-partner').on("click", function (event) {
        event.preventDefault();
        $(".newsletter-signup-partner").modal({ minWidth: 700 });

        var email = $('#signup-email').val();

        $('.contourFieldSet').find('.businessemail').find('input').val(email);

        // replaced the below code with the above line which looks for the input of the email field
        //$('#70567b37-c79a-49b8-9840-eba652ac0d24').val(email); // WRD
        //$('#06805b73-c2af-4d1e-a90d-bdadcdecfa9b').val(email); //wrd
        //$('#8aa3618f-43e5-4426-8b52-0dfb0f01e5f9').val(email); //bank
        //$('#860b849d-6021-4530-97e8-eec1b491494c').val(email); //export
        //$('#894a911a-700f-40e8-96d6-38459641bdd9').val(email); //partner
        //$('#7e50e0eb-3cb6-4c66-b42f-9734d97692f9').val(email);

        $('.signup-block').find('input[type=checkbox]').each(function () {
            var $this = $(this);

            var dataField = $this.data('field'),
	            dataValue = $this.prop('checked');

            $('.contourFieldSet').find('input[type=checkbox][value="' + dataField + '"]').prop('checked', dataValue);
        });

    });

    $(".search-modal-wrd").on("click", function (event) {
        event.preventDefault();
        //console.log('footer signup triggered');
        $(".newsletter-signup-wrd").modal({ minWidth: 700 });
    });

    $(".search-modal-export").on("click", function (event) {
        event.preventDefault();
        //console.log('footer signup triggered');
        $(".newsletter-signup-export").modal({ minWidth: 700 });
    });

    $(".search-modal-circular").on("click", function (event) {
        event.preventDefault();
        //console.log('footer signup triggered');
        $(".newsletter-signup-circular").modal({ minWidth: 700 });
    });

    // Nav functions
    /*
	REMOVING AS PART OF HOMEPAGE REBUILD
	BW 24/12/2016

	var hasTheMobileNavDisplayed = false;
	var mobileFixCount = 0;

	function checkNav() {
		var scroll = $(window).scrollTop();

		if (scroll < 62) {
			if ($('#header').height() != 100) {
				$('.nav-list-holder').animate({ top: '40%' }, 200);
				$('.nav-list').animate({ height: '100px' }, 200);
				$('#nav-logo').animate({ marginTop: '19px' }, 200);
				$('#header').animate({ height: '100px' }, 200);
			}
		}
		if (scroll >= 62) {
			if ($('#header').height() != 61) {
				$('.nav-list-holder').animate({ top: '35%' }, 200);
				$('.nav-list').animate({ height: '61px' }, 200);
				$('#nav-logo').animate({ marginTop: '0px' }, 200);
				$('#header').animate({ height: '61px' }, 200);
			}
		}

		if($('body').css('left') != 'auto'){
			$('#mobileHeader').css('left', "auto");
			hasTheMobileNavDisplayed = true;
		}else{
			if (hasTheMobileNavDisplayed == true) {

				if (mobileFixCount == 0) {
					mobileFixCount++;
					$('#mobileHeader').css('left', "0");
				} else if (mobileFixCount >= 1){
					$('#mobileHeader').css('left', "auto");
					hasTheMobileNavDisplayed = false;
					mobileFixCount = 0;
				}
			}
		}
	}

	var t = setInterval(checkNav, 500);

	$('.nav-list-holder').each(function () {
		var loc = String(window.location.href);
		var loc2 = String($(this).children('a').first().attr('href'));

		loc = loc.replace('https://', '');
		loc = loc.replace('http://', '');
		loc = loc.replace('localhost/', '');
		loc = loc.replace('.html', '');
		loc = loc.split('/');
		loc = String(loc[1]);
		loc = loc.replace('/', '');

		loc2 = loc2.replace('https://', '');
		loc2 = loc2.replace('http://', '');
		loc2 = loc2.replace('localhost/', '');
		loc2 = loc2.replace('.html', '');

		if (loc2.substring(0, 1) == '/') {
			loc2 = loc2.substring(1);
		}

		loc2 = loc2.split('/');
		loc2 = String(loc2[0]);

		loc2 = loc2.replace('/', '');

		if (loc == loc2 && loc != '' && loc != '/') {
			$(this).addClass('active');
		}
	});

	var checkedNavHeights = 0;

	$('#mobileMenu').sidr({
		name:'sidr-mainNav',
		source:'#mainNav',
		onOpen: function() {
			if (checkedNavHeights == 0) {
				checkedNavHeights = 1;
				//fix sub-nav heights
				$('.sidr-class-cbp-tm-submenu-header').each(function () {

					if ($(this).text().length > 20){
						$(this).height(40);

						// get maximum 20 characters
						var subNavText = $(this).text().substring(0,20);
						subNavText = subNavText.substring(0,(subNavText.lastIndexOf(' ')));
						$(this).text(subNavText + "...");
					}
				});
			}
		},
		onClose: function() {
		//console.log('close');
		}
	});

	$('#mobileSearchContainer').hide();
	*/

    //I'm removing this because its horrible and disrupting all my other work.
    //BW - 11/02/2016
    /*
	$('a[href=#]').click(function(e){
		$("html, body").animate({scrollTop:'0px'});
		e.preventDefault();
	});
	*/


    /*
	REMOVING AS PART OF HOMEPAGE REBUILD
	BW 24/12/2016

	$( window ).resize(function() {
		$.sidr('close', 'sidr-mainNav');
	});
	*/

    var $overlay = $('#overlay');
    // $('#search-navigation').click(function(){
    //     if ( $overlay.is(':visible') ) {
    //         $overlay.fadeOut();
    //     } else {
    //         $overlay.fadeIn();
    //         $( "#SearchTerm" ).focus();
    //     }
    // });
    // $('#search-navigation-icon').click(function(){
    //     if ( $overlay.is(':visible') ) {
    //         $overlay.fadeOut();
    //     } else {
    //         $overlay.fadeIn();
    //         $( "#SearchTerm" ).focus();
    //     }
    // });
    $('#search-navigation-div').click(function () {
        if ($overlay.is(':visible')) {
            $overlay.fadeOut();
        } else {
            $overlay.fadeIn();
            $("#SearchTerm").focus();
        }
    });

    $('#mobileSearch').click(function () {
        if ($overlay.is(':visible')) {
            $overlay.fadeOut();
        } else {
            $overlay.fadeIn();
            $("#SearchTerm").focus();
        }
    });

    $('#search-cross').click(function () {
        $overlay.fadeOut();
    });

    $('.news-article-tile').click(function () {
        window.location = $(this).find('a').attr('href');
    });

    $('.newsroom-monitor-tile').click(function () {
        window.location = $(this).find('a').attr('href');
    });

    // Tooltip menu
    /*
	REMOVING AS PART OF HOMEPAGE REBUILD
	BW 24/12/2016
	var menu = new cbpTooltipMenu( document.getElementById( 'cbp-tm-menu' ) );

	var isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
	var isIPAD = /(iPad)/g.test(navigator.userAgent);

	$( "input" ).focus(function() {
		if (isIOS) {
			$('#mobileHeader').css({position:'absolute'});
		}
	});
	$( "input" ).blur(function() {
		if (isIOS) {
			$('#mobileHeader').css({position:'fixed'});
		}
	});

	$( "select" ).focus(function() {
		if (isIOS) {
			if ($(this).hasClass('input-validation-error')) {
				$(this).blur();
				$(this).unbind("focus");
			}
		}
	});

	if (isIPAD){
		document.body.classList.add("iPad");
	}
	*/
});


//brightcove video player
var player, APIModules, videoPlayer, experienceModule;
function onTemplateLoad(experienceID) {
    player = brightcove.api.getExperience(experienceID);
    APIModules = brightcove.api.modules.APIModules;
}

function onTemplateReady(evt) {
    videoPlayer = player.getModule(APIModules.VIDEO_PLAYER);
    experienceModule = player.getModule(APIModules.EXPERIENCE);

    videoPlayer.getCurrentRendition(function (renditionDTO) {

        if (renditionDTO) {
            calulateNewPercentage(renditionDTO.frameWidth, renditionDTO.frameHeight);
        } else {
            videoPlayer.addEventListener(brightcove.api.events.MediaEvent.PLAY, function (event) {
                calulateNewPercentage(event.media.renditions[0].frameWidth, event.media.renditions[0].frameHeight);
            });
        }
    });

    var evt = document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, 0);
    window.dispatchEvent(evt);
}

function calulateNewPercentage(width, height) {
    //logit("function", "calulateNewPercentage");
    //var newPercentage = ((height / width) * 100) + "%";
    //logit("Video Width = ", width);
    //logit("Video Height = ", height);
    //logit("New Percentage = ", newPercentage);
    //document.getElementById("container1").style.paddingBottom = newPercentage;
}
var EFIC = EFIC || {};
EFIC.Interface = EFIC.Interface || {};

(function($, jQuery) {
	'use strict';

	EFIC.Interface.UtilityBar = function( options ) {

		var defaults = {

			selectors: {

				//Module Parent and desktop element selectors
				parentElem: '.utility-bar',
				searchBar: '.search-bar',
				searchTrigger: '.btn-search-trigger',
				searchInput: 'input.global-search'
			},

			desktop_options: {
				searchBarHeight_open: 96
			},

			mobile_options: {
				searchBarHeight_open: 72
			}
		};

		options = $.extend(true, {}, defaults, options );


		/**********************************
		// Declare variables
		**********************************/
		var parent = $(options.selectors.parentElem);

		var $searchBar = $parentElem(options.selectors.searchBar);
		var $searchBarTrigger = $parentElem(options.selectors.searchTrigger);
		var $searchInput = $parentElem(options.selectors.searchInput);

		var $searchBarTrigger_mobile = $('#tertiary-nav').find(options.selectors.searchTrigger);

		/**********************************
		// Helper function to return a jQuery object, which is a child of 'parentElem'
		**********************************/
		function $parentElem( selector ) {
			return $(options.selectors.parentElem+' '+selector);
		}

		/**********************************
		// Interface Events
		**********************************/
		function openSearchBar(option_height){
			//$searchBarTrigger.addClass('active');
			$searchBar.velocity({

                properties: {
                    height: option_height
                },

                options: {
                    duration: 250,

                    complete: function () {
                    	$searchBar.addClass('active');
                    	$searchInput.focus();
                    }
                }
            });
		}

		function closeSearchBar(){
			//$searchBarTrigger.removeClass('active');
			$searchBar.velocity({

                properties: {
                    height: '0'
                },

                options: {
                    duration: 250,

                    complete: function () {
                    	$searchBar.removeClass('active');
                    	$searchInput.blur();
                    }
                }
            });
		}

		function toggleSearchBar(option_height){
			if( $searchBar.hasClass('active')){
				closeSearchBar();
			} else {
				openSearchBar(option_height);
			}
		}

		/**********************************
		// Setup Events
		**********************************/
		//General events
		function bindEvents(){
			$searchBarTrigger.click(function(){
				toggleSearchBar(options.desktop_options.searchBarHeight_open);
				$(this).toggleClass('active');
			});

			$searchBarTrigger_mobile.click(function(){
				toggleSearchBar(options.mobile_options.searchBarHeight_open);
				$(this).toggleClass('active');
			});

			$searchInput.on('keypress', function (e) {
                // submit when user hits enter in text field
			    if (e.keyCode === 13) {

			        console.log('submit search');
			    }
			});
		}

		/**********************************
		// Simple constructor
		**********************************/
		function __construct() {

			// Init
			bindEvents();

		}

		__construct();

		//
		// Make some events accessible from global scope
		//
		return {
			//'sampleEvent': sampleEvent,
		};
	};


})(jQuery, jQuery);
